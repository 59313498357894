<template>
  <h2 v-if="isPast" class="week-start"> {{$t('OVERVIEW.MISSION_WEEK_STARTED') }}</h2>
  <div v-else class="countdown-titles">
    <h2 class="countdown-subtitle">{{$t('OVERVIEW.MISSION_COUNTDOWN_SUBTITLE') }}</h2>
    <h1 class="countdown-title">{{$t('OVERVIEW.MISSION_MEDIA_LITERACY') }}</h1>
  </div>
  <div class="countdown-wrapper">
    <MissionNumberIndicator v-for="date in dates"
                            :key="date.label"
                            :number="date.number"
                            :label="date.label">
    </MissionNumberIndicator>
  </div>
</template>

<script>

import nl from '@/utils/language/nl.json'
import MissionNumberIndicator from './MissionNumberIndicator'
export default {
  name: 'MissionCountdown',
  components: {
    MissionNumberIndicator
  },
  props: {
    startTime: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      isPast: false
    }
  },
  computed: {
    dates: function() {
      return [
        {
          label: nl.DAY,
          number: this.ensureDoubleDigit(this.days)
        },
        {
          label: nl.HOUR,
          number: this.ensureDoubleDigit(this.hours)
        },
        {
          label: nl.MINUTE,
          number: this.ensureDoubleDigit(this.minutes)
        }, {
          label: nl.SECOND,
          number: this.ensureDoubleDigit(this.seconds)
        }
      ]
    }
  },
  created: function() {
    this.calculatedTimes()
    setInterval(() => this.calculatedTimes(), 1000)
  },
  methods: {
    calculatedTimes() {
      const currentTime = (Date.now() / 1000)
      if (this.startTime < currentTime) {
        this.isPast = true
        return
      }
      // get total seconds between the times
      var delta = Math.abs(this.startTime - currentTime)

      // calculate (and subtract) whole days
      this.days = Math.floor(delta / 86400)
      delta -= this.days * 86400

      // calculate (and subtract) whole hours
      this.hours = Math.floor(delta / 3600) % 24
      delta -= this.hours * 3600

      // calculate (and subtract) whole minutes
      this.minutes = Math.floor(delta / 60) % 60
      delta -= this.minutes * 60

      // what's left is seconds
      this.seconds = Math.floor(delta % 60) // in theory the modulus is not required
    },
    ensureDoubleDigit(num) {
      const text = num.toString()
      return text.length < 2 ? '0' + num : num
    }
  },
}
</script>

<style lang="scss" scoped>

@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";


.countdown-wrapper{
  display: flex;
  gap: rem(5);
  justify-content: center;
}

.countdown-title {
  font-size: rem(34);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(41);
  margin-bottom: rem(24);
}

.countdown-subtitle {
  opacity: 0.6;
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(17);
  font-weight: 300;
  text-transform: uppercase;
}

.week-start{
  margin-bottom: rem(10);
}

</style>
