<template>
  <div v-if="showRanking" class="mission-end-score">
    <div class="text-wrapper">
      <h2 class="countdown-subtitle">{{ $t('OVERVIEW.MISSION_RESULTS') }}</h2>
      <p class="countdown-title">{{ $t('OVERVIEW.MISSION_MEDIA_LITERACY') }}</p>
    </div>
    <div class="number-indicator-wrapper">
      <MissionNumberIndicator
        :number="endPosition"
        label="Eindpositie"
      />
      <MissionNumberIndicator
        :number="collectedBits"
        label="Bits verzameld"
      />
    </div>
  </div>
</template>

<script>
import MissionNumberIndicator from './MissionNumberIndicator'

export default {
  name: 'MissionEndScore',
  components: {
    MissionNumberIndicator
  },
  props: {
    endPosition: {
      type: Number,
      required: true
    },
    collectedBits: {
      type: Number,
      required: true
    },
    showRanking: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
.number-indicator-wrapper {
  display: flex;
  gap: rem(6.5)
}

.countdown-title {
  font-size: rem(34);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(41);
  margin-bottom: rem(24);
}

.countdown-subtitle {
  opacity: 0.6;
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(17);
  font-weight: 300;
  text-transform: uppercase;
}
</style>
