<template>
  <div class="mission-center-section" :class="stylePreset">
    <div class="background top-left" />
    <div v-if="useBottomBackgroundPart" class="background bottom-right" />

    <div class="center-image-wrapper">
      <div class="gradient" />
      <img :src="centerImageSrc" alt="" />
    </div>
    <div class="middle-text">

    </div>
    <JokerInfo v-if="jokerId !== null" :title="jokerData.name" :description="jokerData.description" :image-src="jokerData.icon" />
    <div v-if="buttonText" class="button-wrapper">
      <BasicButton class="bottom-button" :button-text="buttonText" @click="navigateToRoute"/>
    </div>
  </div>
</template>

<script>
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { SET_BASIC_MODAL_DATA } from '@/store/mutations'
import BasicButton from '@/components/elements/basic/BasicButton'
import JokerInfo from '@/components/elements/missions/JokerInfo'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import { jokerTypesArr } from '@/utils/data/jokerTypes'

export default {
  name: 'MissionCenterSection',
  components: {
    BasicButton,
    JokerInfo
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonRoute: {
      type: String,
      required: true
    },
    centerImageSrc: {
      type: String,
      required: true
    },
    dayNumber: {
      type: Number,
      required: false,
      default: null
    },
    jokerId: {
      type: Number,
      required: false,
      default: null
    },
    stylePreset: {
      type: String,
      required: false,
      default: 'blue'
    },
    useBottomBackgroundPart: {
      type: Boolean,
      required: false,
      default: false
    },
    modal: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const store = useStore()
    const jokerData = computed(() => jokerTypesArr.find((joker) => joker.id === props.jokerId))

    function navigateToRoute() {
      if (props.modal) {
        store.commit(SET_BASIC_MODAL_DATA, props.modal)
      }
      else {
        window.location.href = props.buttonRoute
      }
    }

    return {
      ROUTE_NAMES_TEACHER_PORTAL,
      navigateToRoute,
      jokerData
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.mission-center-section {
  border-radius: rem(20);
  text-align: center;
  height: rem(284);
  position: relative;
  margin-bottom: rem(50);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: rem(31) rem(49) rem(0);
  // z-index: -2;
  flex-grow: 1;
  background: url("../../../assets/img/background-game-pattern_1920x1080.png") !important;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.top-left {

    }
    &.bottom-right {
      transform: rotate(180deg);
    }
  }

  .center-image-wrapper {
    height: 100%;
    position: relative;
    img {
      @include position(absolute, $top: 50%, $left: 50%);
      height: 90%;
      //object-fit: contain;
      z-index: 2;
      transform: translate(-50%, -50%)
    }
    .gradient {
      width: rem(350);
      height: 100%;
      position: absolute;
      background: radial-gradient(circle, rgba(#6C7BE6, 0.5) 0%, rgba(77,71,102,0) 50%);
      transform: translateX(-50%);
      z-index: 1;
      left: 50%;
    }
  }

  .middle-text {
    width: rem(250);
    align-items: center;
    margin-left: rem(-20);
    p {
      font-size: rem(14);
      line-height: rem(17);
      text-align: center;
      opacity: .6;
      margin-bottom: rem(8);
      text-transform: uppercase;
    }

  }

  .button-wrapper {
    height: rem(57);

    .bottom-button{
      position: absolute;
      left: 50%;
      transform: translate(-50%,50%);
      font-size: rem(18);
      padding: rem(15) rem(15);
      white-space: nowrap;
      bottom: 0;
      z-index: 5;
      min-width: 230px;
    }
  }

  &.purple {
    background: linear-gradient(180deg, #35234E 0%, #252345 100%);
    .background {
      background: url("../../../assets/img/img_pattern_day2.png") no-repeat;
    }
  }
  &.blue {
    background: linear-gradient(180deg, #6985DC 0%, #65B9E0 100%);
    .background {
      background: url("../../../assets/img/elements_bg_pattern_cta2.png") no-repeat;
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .mission-center-section {
    align-items: center;
    height: rem(320);
    justify-content: center;
    flex-direction: row;
  }
}

</style>
