<template>
  <div class="mission-number-indicator">
    <div class="time-indicator-wrapper">
      <div class="time-indicator-number">
        {{ number }}
        <div class="time-indicator-underline"></div>
      </div>
    </div>
    <div class="time-indicator-label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MisisonTimeIndicator',
  props: {
    number: {
      type: [Number, String],
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.mission-number-indicator {
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
}

.time-indicator-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: inset 0 1px 3px 0 #171C3E;
  background-color: #272346;
  border-radius: rem(5);
}
.time-indicator-number{
  text-align: center;
  margin: rem(20) rem(5) rem(9);
  color: white;
  font-size: rem(40);
  font-weight: 800;
}

.time-indicator-underline{
  background: rgba(#04050E, 0.24);
  box-shadow: inset 0 1px 3px 0 #171C3E;
  height: rem(4);
  margin: rem(8) auto 0;
  border-radius: rem(10);
  max-width: rem(78);
}

.time-indicator-label{
  color: rgba($color: #ffffff, $alpha: 0.6);
  text-transform: uppercase;
  margin-top: rem(8);
  font-size: rem(14);
}

</style>
