<template>
  <div class="joker-info-wrapper">
    <p class="joker-earn-title title">Te verdienen joker:</p>
    <div class="joker-info">
      <p class="title">{{ title }}</p>
      <div class="bottom">
        <div class="image-wrapper">
          <div class="gradient" />
          <img :src="imageSrc" alt="">
        </div>
        <p>{{ description }}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'JokerInfo',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.joker-info-wrapper {
  position: absolute;
  right: rem(49);
  margin-bottom: 12px;
  max-width: 260px;

  .joker-earn-title {
    text-align: left;
    font-size: 14px;
  }

  .title {
    font-weight: 500;
    margin-bottom: rem(8);
    text-transform: uppercase;
  }

  .joker-info {
    background-color: #3C3165;
    border-radius: rem(17.5);
    padding: rem(14) rem(16);
    p {
      color: white;
      font-size: rem(14);
      line-height: rem(17);
      text-align: left;
    }


    .bottom {
      display: flex;
      gap: rem(17);
      justify-content: center;
      align-items: center;
      .image-wrapper {
        position: relative;
        width: rem(44);
        height: rem(44);
        flex-shrink: 0;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          left: 0;
        }
        .gradient {
          width: 100%;
          height: 100%;
          position: absolute;
          background: radial-gradient(circle, rgba(#6C7BE6, 0.5) 0%, rgba(77,71,102,0) 50%);
          transform: scale(2, 2);
        }
      }
      p {
        line-height: rem(18);
      }
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .joker-info-wrapper {
    top: 0;
    z-index: 1;
    width: 300px;
    margin-top: 32px;
    margin-bottom: 32px;
    .joker-earn-title {
      text-align: center !important;
    }
  }
}

</style>
