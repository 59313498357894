<template>
  <div class="pre-week-demo-wrapper">
    <DemoMissionBanner :class-id="classId"/>
    <!--    todo RESTORE ON 13 SEP OR WRITE CODE-->
    <!--    todo RESTORE ON 13 SEP OR WRITE CODE-->
    <!--    todo RESTORE ON 13 SEP OR WRITE CODE-->
    <div class="demo-mission-instructions">
      <h2 class="page-section-title"> {{ $t('OVERVIEW.DEMO_MISSION_INSTRUCTION_TITLE') }}</h2>
      <p class="page-section-text"> {{ $t('OVERVIEW.DEMO_MISSION_INSTRUCTION_TEXT_ONE') }}</p>
    </div>
    <div>
      <AccordionItem
        title="Wat gaan jullie doen?"
        text="• De eerste MediaMasters aflevering bekijken<br>• Vier vragen beantwoorden<br>• Praten over een stelling (optioneel)"
        class="faq-item"
      />
      <AccordionItem
        title="Wat heb je nodig?"
        :text="'• Een digibord met computer<br>• Snel internet (min. 10 Mbit/s)<br>• Inloggegevens van je <a href=&quot;' + cmsBaseUrl + '&quot;>Mijn MediaMasters-account</a><br>• Klassennaam<br>• Optioneel: extra Chromebooks of tablets om de leerlingen naar antwoorden te laten zoeken'"
        class="faq-item"
      />
    </div>
    <h2 class="additional-exercises-title">{{ $t('EXTRA_EXERCISES.TITLE_PRE_WEEK') }}</h2>
    <!--  Todo: Find a way to determine amount of times played  -->
    <MediaQuizProgress
      :class-id="classId"
      :pre-week="true"
    />
  </div>
</template>

<script>

import AccordionItem from '@/components/elements/accordion/AccordionItem'
import DemoMissionBanner from '@/components/elements/missions/DemoMissionBanner'
import MediaQuizProgress from '@/components/elements/missions/MediaQuizProgress'

export default {
  name: 'PreWeekDemo',
  components: {
    AccordionItem,
    DemoMissionBanner,
    MediaQuizProgress
  },
  props: {
    classId: {
      type: Number,
      default: -1,
    }
  },
  setup() {
    const cmsBaseUrl = process.env.VUE_APP_ROOT_CMS
    const demoMissionUrl = process.env.VUE_APP_ROOT_GAME + '/demo'

    return {
      cmsBaseUrl,
      demoMissionUrl
    }
  },
  template: 'PreWeekDemo'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.pre-week-demo-wrapper{
  .demo-mission-instructions{
    margin-top: rem(32);

    .page-section-title {
      margin-bottom: rem(12);
    }

    .page-section-text {
      margin-bottom: rem(8);
    }
  }
}

.faq-item {
  margin-top: rem(12);
}

.additional-exercises-title {
  margin: 2rem 0;
}
</style>
